import { Navigate, Outlet } from "react-router-dom";  
interface Routesprops {
    isAllowed?: any,
    redirectPath?: any,
    children?: any,
} 
const PrivateRoutes: React.FunctionComponent<Routesprops> = (props) => { 
    if (!props.isAllowed) {
        return <Navigate to={props.redirectPath} replace />;
    }  
    return props.children ? props.children : <Outlet />; 
}
export default PrivateRoutes;