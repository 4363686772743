import { Button, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react';  
import { Col, Row } from 'react-bootstrap';  
import { message } from 'antd';  
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton'; 
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { WebApi } from 'shared/WebApi';
import TdtTextarea from 'components/websitecomponents/TdtTextarea';
import TdtCKeditor from 'components/websitecomponents/TdtCKeditor';

interface TdtBookDrwerprops {
  open?: boolean;
  onClose?: any;
  title?: string;
  destroyOnClose?: boolean;
  placement?: string;
  singlePkgIdFaqs?:any
}  

const AddSinglePackageFaqs: React.FC<TdtBookDrwerprops> = (props) => { 

  const [form] = Form.useForm();
  const formRef = React.useRef<FormInstance>(null);  
  
  const onFinish = async (values: any) => {   
    try {
      const response = await WebApi('post', `api/insert_singlePackagefaq_byPkgId/${props.singlePkgIdFaqs}`, values);  
      const responseData: any = response; 
      if (responseData.status === 200) { 
        message.success('Package Faq added');  
      } else {
        console.error('Error: Request error');  
      }
    } catch (error: any) {
      console.error('Error:', error);  
    }
  }; 
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  };

  const getHomeFaqs = async () => {   
    try {
      const response = await WebApi('get', `api/get_singlePackagefaq_byPkgId/${props.singlePkgIdFaqs}`);
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData = responseData.data.data;
        
        form.setFieldsValue({  
            singlepkg_faqs: modifiedData.qa.map((option: any) => ({
            pkg_Question: option.question,       // Map the question field
            pkg_answer: option.answer,           // Map the answer field
          })), 
        }); 
      }
    } catch (error: any) {
      console.error('Error:', error);  
    } 
  }; 

  useEffect(() => { 
    getHomeFaqs();
  }, []);  

  return (
    <TdtDrawer title={props.title} open={props.open} placement={props.placement} onClose={props.onClose} width={'1150px'} destroyOnClose={props.destroyOnClose}>      
      <Row>
        <Col sm={12}>
          <Form
            name="add_faqsform"
            form={form}
            ref={formRef} 
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          > 
            <Row>  
              <Col sm={12} md={12} className='d-flex flex-column'> 
                <label className='mb-2'>Add Frequently ask questions</label>
                <Form.List name="singlepkg_faqs">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'pkg_Question']}
                            rules={[{ required: true, message: 'Add Question' }]}>
                            <Input type="text" placeholder="Add Question" /> 
                          </Form.Item> 
                          <Form.Item
                            {...restField}
                            name={[name, 'pkg_answer']}
                            rules={[{ required: true, message: 'Add Answer' }]}>
                            <TdtCKeditor />
                          </Form.Item>  
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space> 
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Additional Options
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List> 
              </Col>   
              <Col sm={12} md={12}>
                <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5 mt-3'/> 
              </Col>  
            </Row>
          </Form>
        </Col>  
      </Row>
    </TdtDrawer>
  );
};

export default AddSinglePackageFaqs;
