import {Tabs } from 'antd'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react'; 
import type { TableColumnsType, TabsProps } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable';

interface TdtPkgInfoDrawerprops {
  open?: boolean; 
  onClose?: any;  
  destroyOnClose?: boolean; 
} 

const TdtPkgInfoDrawer: React.FC<TdtPkgInfoDrawerprops> = (props) => {
   
    const onChange = (key: string) => {
      console.log(key);
    };
    
      const columns = [
        {
          title: 'Duration',
          dataIndex: 'duration',
        },
        {
          title: 'Departure Point',
          dataIndex: 'departurepoint',
          // sorter: {
          //   compare: (a, b) => a.chinese - b.chinese,
          //   multiple: 3,
          // },
        },  
        {
          title: 'Pick up Time',
          dataIndex: 'pickuptime',
          // sorter: {
          //   compare: (a, b) => a.chinese - b.chinese,
          //   multiple: 3,
          // },
        },  
        {
          title: 'Pick up & Drop off',
          dataIndex: 'pickupdropoff',
          // sorter: {
          //   compare: (a, b) => a.chinese - b.chinese,
          //   multiple: 3,
          // },
        },  
      ]; 
      
      const dataSource = [
        {
          key: '1',
          duration: '',
          departurepoint: 'Hotel / Apartment',
          pickuptime: '',
          pickupdropoff: 'Yes',
        } 
      ];


    const items: TabsProps['items'] = [
      {
        key: '1',
        label: 'Description',
        children: <><h5 className='pb-4'>Description</h5>
        <h5 className='pb-4'>Inclusions</h5>
        <h5 className='pb-4'>Additional Information</h5>
        <h5 className='pb-4'>Important Information</h5>
            <ul>
                <li>
                    <a href='#'>✓</a> Once you fill out our form online and do the needful you will receive a confirmation on your screens immediately.
                </li>
                <li>
                    <a href='#'>✓</a>  You will also receive an email confirmation from our booking team confirming the details of your trip.
                </li>
                <li>
                    <a href='#'>✓</a>  There would be a follow via phone call (on International Number) before the trips due date, you will also receive text messages via WHATS APP on the developments leading to your visit to the Arabian Deserts.
                </li>
            </ul>
            <><TdtTable tblheadcolumns={columns} tabledata={dataSource}/></>
            
        </>
        },
      {
        key: '2',
        label: 'Inclussions',
        children: <><h5 className='pb-4'>Inclusions</h5></>,
      },
      {
        key: '3',
        label: 'Timings',
        children: <><h5 className='pb-4'> Timings</h5></>,
      },
      {
        key: '4',
        label: 'Useful Info',
        children: <><h5 className='pb-4'>Useful Info</h5></>,
      },
      {
        key: '5',
        label: 'Timing',
        children: <><TdtTable tblheadcolumns={columns} tabledata={dataSource}/></>,
      },
    ];

  return (
    <TdtDrawer title={'Tour Title | Duration:'} open={props.open} onClose={props.onClose} width={'1110px'} className={'pkginfodrwr'} destroyOnClose={props.destroyOnClose}>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </TdtDrawer>
  );
};

export default TdtPkgInfoDrawer;
