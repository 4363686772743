import React from 'react';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { DatePickerProps } from 'antd';
import { DatePicker, Form } from 'antd';
import moment from 'moment';

dayjs.extend(customParseFormat);

interface Grcuploadprops {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    required?: boolean;
    requiredMessage?: string;
    ruleType?: any;
    defaultValue?: any;
    onChange?:any
}
const dateFormat = 'YYYY/MM/DD';

const TdtDate: React.FC<Grcuploadprops> = (props) => (
    <Form.Item label={props.label} name={props.name}> 
        <DatePicker onChange={props.onChange} format={dateFormat} className={props.className}/>
    </Form.Item>
);

export default TdtDate;
  