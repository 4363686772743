import React from 'react';
import { AutoComplete, Form, Input, Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';

type propsType = {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    required?: boolean;
    requiredMessage?: string;
    placeholder?: string;
    prefix?: any; //add text or icon at start of input
    suffix?: any; //add text or icon at end of input
    offset?: number;
    span?: number;
    allowClear?: boolean;
    type?: string;
    ruleType?: any;

    defaultValue?: string;
    maxLength?: number;
    showCount?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    value?: any;
    size?: SizeType; //three type of sizes 'large'|'middle'|'small'
    onFocus?: any;
    onBlur?: any;
    onKeyUp?: any;
    onkeydown?: any;
    onchange?: any;
    websiteOptions?: any;
    onWebsiteChange?: any;
    pattern?: any;
    status?: "" | "error" | "warning" 
    min?: any; 
    max?: any;


}
const TdtInput: React.FC<propsType> = ({ onchange, ...propsType }) => {
    return (
        <Form.Item
            label={propsType.label}
            name={propsType.name}
            rules={[{ type: propsType.ruleType, required: propsType.required, message: propsType.requiredMessage, pattern: propsType.pattern }]}
            wrapperCol={{ offset: propsType.offset, span: propsType.span }}
        >
                {/* <AutoComplete options={propsType.websiteOptions} onChange={propsType.onWebsiteChange} placeholder="website"></AutoComplete> */}
            <Input 
            min={propsType.min} 
            max={propsType.max} 
            status={propsType.status} disabled={propsType.disabled} id={propsType.id} value={propsType.value} onChange={onchange} size={propsType.size} type={propsType.type} prefix={propsType.prefix} suffix={propsType.suffix} placeholder={propsType.placeholder} allowClear={propsType.allowClear} readOnly={propsType.readonly} defaultValue={propsType.defaultValue} />
        </Form.Item>
    );
}

export default TdtInput;