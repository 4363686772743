import { Button, Form, Input, Space, Spin, message } from 'antd';
import { FormInstance } from 'antd/es/form';
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { WebApi } from 'shared/WebApi';
import TdtSelect from 'components/websitecomponents/TdtSelect';
import TdtCKeditor from 'components/websitecomponents/TdtCKeditor';
import TdtImage from 'components/websitecomponents/TdtImage';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaRegTrashCan, FaTrashCan } from 'react-icons/fa6';

const projectpath = process.env.REACT_APP_API_URL;

interface TdtBookDrawerProps {
  open?: boolean;
  onClose?: any;
  title?: string;
  destroyOnClose?: boolean;
  placement?: string;
  editPackageId?: any;
}

const EditPackageDrawer: React.FC<TdtBookDrawerProps> = (props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [form] = Form.useForm();
  const [allCategories, setAllCategories] = useState<any[]>([]);
  const [shortEditorData, setShortEditorData] = useState<string>('');
  const [longEditorData, setLongEditorData] = useState<string>('');
  const [packageData, setPackageData] = useState<any>(null);
  const [packageAdOptId, setPackageAdOptId] = useState<any>(null);
 
   const [selectedCategoryId, setSelectedCategoryId] = useState<any[]>([]); 
  const [transferType, setTransferType] = useState<any[]>([]);
  const [durationtype, setDurationType] = useState<any[]>([]);
  const [imageUpload, packageImageUpload] = useState<File | null>(null);
  const [imageThumbnailUpload, packageImageThumbnailUpload] = useState<File | null>(null);
  const [packageSlideImages, setPackageSlideImages] = useState<File[]>([]);
  const [slideImages, setSlideImages] = useState<File[]>([]);
  
  const [disableButton, setDisableButton] = useState(false) 
    const [checkEmptyVal, setCheckEmptyVal] = useState(false)
    const [spiningLoader, setSpiningLoader] = useState(false)
  
  
  const onFinish = async (values: any) => {
    
    setDisableButton(true) 
    setSpiningLoader(true)
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('package_slideImgsss[]', file);
    });
    values.package_image = imageUpload;
    values.package_slideImgs = packageSlideImages;
    values.package_image_thumbnail = imageThumbnailUpload;
    values.transfer_type = transferType;
    values.cat_id = selectedCategory.id;
    values.cat_title = selectedCategory.name;
    values.long_description = longEditorData;
    values.description = shortEditorData;
    values.duration_type = durationtype;
    values.adopt_id = packageAdOptId; 
    values.selectedComboPkgId = selectedComboPkgId

    try {
      const response = await WebApi('post', `api/update_package/${props.editPackageId}`, values, 'multipart');
      const responseData: any = response;
      if (responseData.status === 200) {
        message.success('Product Updated');
        setDisableButton(false)
        setSpiningLoader(false)
      } else {
        console.error('Error: Request error');
        setDisableButton(true) 
        setSpiningLoader(true)
      }
       
    } catch (error: any) {
      console.error('Error:', error);
      setDisableButton(true) 
      setSpiningLoader(true)
    }
  };

  const getCategories = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_categories');
      const responseData: any = response;
      if (responseData.status === 200) {
        
        const modifiedData = responseData.data.data.map((item: any) => ({
          label:  item.title,
          value:  item.cat_id,
          image:  item.image,
        }));

        setAllCategories(modifiedData);
        
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 
 
  
  

  const onChangeCategory = (value: string[]) => {
    setSelectedCategoryId(value);
     
  };  
    const [selectedCategory, setSelectedCategory] = useState<{ id: string; name: string; image: string }>({
      id: '',
      name: '',
      image:''
    });
    
    const [comoboCatVal, setComoboCatVal] = useState(false)
    const onChangeCategoryN = (value: string, option: any) => {
      if (option && option.label && option.image) {
        setSelectedCategory({
          id: value,
          name: option.label,
          image: option.image,
        });
      } else {
        console.error('Option object does not have the expected properties:', option);
      }
      if ( option.label == 'Combo Deals' || option.label == 'Combo Deal') {
        setComoboCatVal(true);
      }else {
        setComoboCatVal(false);
      }
      
    };
  
    
    const [selectedComboPkgId, setSelectedComboPkgId] = useState<{id: string}>({id: ''}); 
  
    const onChangeComboPkgN = (value: string, option: any) => {
      if (value) {
        setSelectedComboPkgId({
          id: value,  
        }); 
      
      } else {
        console.error('Option object does not have the expected properties:', option);
      }
      
    };
    
   

  const getPackageById = async () => {
    try {
      const response = await WebApi('get', `api/get_package_byId/${props.editPackageId}`);
      const responseData: any = response;
      console.log('API Response:', responseData); // Log the response for debugging
  
      if (responseData.status === 200) {
        const modifiedData = responseData.data.data;
        setPackageData(modifiedData);
        setPackageAdOptId(modifiedData.ad_opt_id);
        // Log the modified data
        console.log('Modified Package Data:', modifiedData);
  
        setShortEditorData(modifiedData.description || "");
        setLongEditorData(modifiedData.long_description  || "");
        
        setSelectedCategory({
          id: modifiedData.cat_id,
          name: modifiedData.cat_title,
          image: '', // Set if you have an image for the category
        });
  
        setTransferType(modifiedData.transfer_type);
        setDurationType(modifiedData.duration_type);
        setSlideImages(modifiedData.SlideImages);
        form.setFieldsValue (modifiedData) 
        const additionalOptionsArray = Object.values(modifiedData.AdditionalOptions); 
        form.setFieldsValue({  
          package_AdditionalOpt: additionalOptionsArray?.map((option: any) => ({
            package_AdditionalOptTitle: option?.title,
            package_AdditionalOptPrice: option?.price,
            package_AdditionalOptPersons: option?.persons,
          })),
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
   
  useEffect(() => {
    getCategories();
    getPackageById();
    getProducts();
  }, [props.editPackageId]);

  const packageImageThumbnailUploadFun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    packageImageThumbnailUpload(file);
  };
 

  const packageSlideImagesFun = (e: ChangeEvent<HTMLInputElement>, fieldIndex: number) => {
    const fileList = e.target.files;
    if (fileList) {
      const updatedFiles = [...packageSlideImages];
      updatedFiles[fieldIndex] = fileList[0];
      setPackageSlideImages(updatedFiles);
      form.setFieldValue(['package_slideImgsO', fieldIndex, 'package_slideImgsf'], fileList[0]);
    } 
  };
 
                
   
  const onChangeTransfertypeFun = (value: string[]) => {
    setTransferType(value);
  };
  
  
  const deletePackageSlideImage = async (package_img_id: any) => {
    try {
      const response = await WebApi('post', `api/delete_package_slideImageByPkgID/${package_img_id}`); 
      const responseData: any = response; 
      if (responseData.status === 200) { 
        message.success('Package Slide Image deleted successfully');
        getPackageById();  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 
  
 
   const [getAllProducts, setGetAllProducts] = useState<any[]>([]);  
   const getProducts = async () => {
     try {
       const response = await WebApi('post', 'api/get_package_all');
       const responseData: any = response;
       if (responseData.status === 200) {
         
         const modifiedData = Object.values(responseData.data.data).map((item: any) => ({
           label:  item.title,
           value:  item.pid, 
         }));
         setGetAllProducts(modifiedData);
        
       } 
     } catch (error: any) {
       console.error('Error:', error);
     }
   };

  
  return (
    <TdtDrawer
      title={props.title}
      open={props.open}
      placement={props.placement}
      onClose={props.onClose}
      width={'1150px'}
      destroyOnClose={props.destroyOnClose}
    >  
    <Spin tip="Pleas Wait Updating Package" spinning={spiningLoader} size="small"> 
      <Row>
        <Col sm={12}>
          <Form
            name="add_product"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Row>
              <Col sm={12} md={3}>
              <TdtSelect
                label='Select Category'
                allowClear={false}
                optionList={allCategories}
                value={selectedCategory.id} // Ensure this reflects the selected category's ID
                onChange={onChangeCategoryN}
                required 
                requiredMessage="Category is Required"
                 
              />
                  </Col> 
                  
                <Col sm={12} md={3} className={`${checkEmptyVal ? 'danger' : ''  }`}>
                <TdtSelect   required={true} requiredMessage='Select combo package first' label='Select Combo Package(Leave Blank if it not combo package) ' mode='multiple' maxCount={2} allowClear={false} optionList={getAllProducts} onChange={onChangeComboPkgN}/>
            </Col>  
              <Col sm={12} md={3}>
                <TdtInput label="Title" required requiredMessage='add package title'  name="title" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput  type='number'  required requiredMessage='price is required'  label="Price" name="price" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput type='number' required label="Discount" name="discount_price" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput type='number'  requiredMessage='child price required' label="Child Price" name="child_price" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput type='number' label="Child Discount" name="child_discount_price" />
              </Col>
              <Col sm={12} md={3}>
                <TdtSelect  requiredMessage='duration type is required'
                  label='Select Duration Type' 
                  allowClear={false} 
                  optionList={[
                    { value: 'days', label: 'Days' },
                    { value: 'hours', label: 'Hours' },
                  ]}
                  value={durationtype} 
                  onChange={(value:any) => setDurationType(value)} 
                />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput  type='number' requiredMessage='duration is required'  className='inputnuberset' label='Duration / Valid for' name='duration' placeholder='Number of days e.g 2,3' />
              </Col>  
              <Col sm={12} md={3}>  
                <TdtInput type='number' label="Rating" name="rating"  placeholder='rating between 0 to 5'/>
              </Col>
              <Col sm={12} md={3}>
                <TdtInput label="Tags" name="tags" />
              </Col>
              <Col sm={12} md={3}>
                  <TdtInput label='Pick up Time' name='pickup_time' placeholder='e.g: 8:30 to 9:00 AM'/>
              </Col> 
              <Col sm={12} md={3}>
                <label className="customlabel">Product Thumbnail</label>
                <input type="file" onChange={packageImageThumbnailUploadFun} />
              </Col> 
              <Col sm={12} md={3}> 
                  <TdtSelect 
                    label='Select Transfer Type' 
                    allowClear={false} 
                    optionList={[ { value: 'sharing', label: 'Sharing' }, { value: 'private', label: 'Private' },  ]} // Define options for transfer type
                    value={transferType} 
                    onChange={(value:any) => setTransferType(value)} 
                  />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput  type='number' label='Transfer Adult Price' name='transfer_type_adultprice'placeholder='Price per person' />
              </Col>  
              <Col sm={12} md={3}>
                  <TdtInput  type='number' label='Transfer Persons' name='transfer_type_persons' placeholder='Number of persons e.g 1, 2' />
              </Col>   
              <Col sm={12} md={3}>
                  <TdtInput  type='number' label='Transfer Child Price' name='transfer_type_childprice' />
              </Col>   
              <Col sm={6}>
              <label className='mb-2'>Package Additional Options</label>
                <Form.List name="package_AdditionalOpt">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'package_AdditionalOptTitle']}
                            rules={[{ required: true, message: 'Missing Additional Options Title' }]}
                          >
                            <Input type="text" placeholder="Package additional opt title " /> 
                          </Form.Item> 
                          <Form.Item
                            {...restField}
                            name={[name, 'package_AdditionalOptPrice']}
                            rules={[{ required: true, message: 'Missing Additional Options Price' }]}
                          >
                            <Input  type='number'  placeholder="Package additional opt price " /> 
                          </Form.Item> 
                          <Form.Item
                            {...restField}
                            name={[name, 'package_AdditionalOptPersons']}
                            rules={[{ required: true, message: 'Missing  number of persons additional opt' }]}
                          > 
                            <Input  type='number'  placeholder="Number of persons " /> 
                          </Form.Item> 
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space> 
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Additional Options
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List> 
              </Col> 
              <Col sm={12} md={6}> 
                <label className='mb-2'>Add Package Images  <div className='d-flex imgslidsimgs'>
                  {slideImages.map((item: any) => (
                      <div> 
                        <TdtIcon onClick={()=>deletePackageSlideImage(item.package_img_id)} icon={<FaRegTrashCan />} />
                        {/* <TdtIcon icon={FaTrashCan} onClick={deletePackageSlideImage(item.package_img_id)}/> */}
                        <TdtImage key={item.id} preview={true} src={projectpath+"public/panelassets/images/uploads/"+item.image} classNameimg=''/>
                      </div>
                      ))}</div>    
                </label>
                <Form.List name="package_slideImgsO">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'package_slideImgsf']}
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e.target.files?.[0]}
                            rules={[{ required: true, message: 'Missing Package Image' }]}
                          >
                            <Input
                              type="file"
                              onChange={(e) => packageSlideImagesFun(e, index)}
                              placeholder="add product Image"
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Package Slide Images
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List> 
              </Col> 
              <Col sm={12}> 
                <label className='pb-3'>Short Description Inclusions</label>
                <TdtCKeditor
                  label="Short Description"
                  name="description"
                  data={shortEditorData}
                  onChange={(data: string) => setShortEditorData(data)}
                />
              </Col>
              <Col sm={12}>
              <label className='pb-3'>Long Description</label>
                <TdtCKeditor
                  label="Long Description"
                  name="long_description"
                  data={longEditorData}
                  onChange={(data: string) => setLongEditorData(data)}
                />
              </Col> 
              <Col sm={12}>
                <TdtButton disable={disableButton}   label="Update" htmlType="submit" classNamebtn='me-2 px-5 mt-3'/>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
    </TdtDrawer>
  );
};

export default EditPackageDrawer;
