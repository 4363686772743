import React, { useEffect } from 'react';
import { Form, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import {WebApi}  from '../shared/WebApi'
import { Col, Container, Row } from 'react-bootstrap';
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaEnvelope, FaLock } from 'react-icons/fa6';
import TdtButton from 'components/websitecomponents/TdtButton'; 
 
interface LoginProps {
    children?: any,
}
 
const Login: React.FC<LoginProps> = (props) => { 
  
  const [form] = Form.useForm() 
  const navigate = useNavigate(); 

  const onFinish = async (values: any) => { 
      values.token = "";
      localStorage.setItem('token', values.token) 
      try {
          const response = await WebApi('post', 'api/user_login' , values, values.token); 
          const responseData: any = response;
          
          if (responseData.status === 200) {

              const modifiedData = responseData.data;

              if (modifiedData.status === true) {  
                localStorage.setItem('token', modifiedData.data.access_token) 
                localStorage.setItem('validationtoken', modifiedData.data.validation_token) 
                localStorage.setItem('role', modifiedData.data.role) 
                localStorage.setItem('tokentype', modifiedData.data.token_type) 
                //  
                message.success('login succesfully')
                navigate('/view-packages')
                 debugger
              } else  {
                console.error('Error:', modifiedData.message);  
              }
          } else  {
            console.error('Error: Request error');  
            message.error(responseData.data.message) 
          }
      } catch (error:any) {
          console.error('Error:', error);  
      }
  }; 

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };  

  
    useEffect(() => {
      
  localStorage.clear();
    }, []);
  
  return (
    <Container fluid className='loginpage'> 
      <Container >
        <Row className='justify-content-center'>
            <Col md={6} className='colonelogin colloginp'>
              <h2> Welcome to The Deluce Travel </h2>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum beatae reiciendis pariatur, deleniti soluta perspiciatis quo ducimus id aspernatur maiores omnis recusandae aperiam totam? Voluptates accusamus a consequatur aliquid tenetur.</p>
            </Col>
            <Col md={6} className='coltwologin colloginp'>
            <Form
              name="LoginForm"
              form={form}  
              // initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical" 
            >
               <Row>
               <Col sm={12}>
                  <TdtInput
                      className="inputclas class-subject"
                      name="email"
                      type="email"
                      required={true}
                      requiredMessage="Please input your Email!"
                      label='Enter Email'
                      placeholder='email address'
                      allowClear={true}
                      prefix={<TdtIcon icon={<FaEnvelope/>}/>}
                      size="large"
                      ruleType='email'
                  />  
                </Col>
               <Col sm={12}>
                  <TdtInput
                      className="inputclas class-password"
                      name="password"
                      type="password"
                      required={true}
                      requiredMessage="Please input your Password!"
                      label='Enter Password'
                      placeholder='Enter Password'
                      allowClear={true}
                      prefix={<TdtIcon icon={<FaLock/>}/>}
                      size="large"
                      ruleType='password'
                  />  
                </Col>
                <Col sm={12} md={12}>
                  <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2'/>
                  {/* <a href='/forget-password'>Forget Password</a> */}
                </Col>
              </Row> 
              </Form> 
            </Col>
        </Row>
      </Container>
    </Container> 
  );
}
 
export default Login;