import { Avatar, Badge, Button, Form, Input, InputNumber, message, Tag } from 'antd';
import form, { FormInstance } from 'antd/es/form';
import TdtSelect from 'components/websitecomponents/TdtSelect';
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react'; 
import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom';
import TdtDate from 'components/websitecomponents/TdtDate';
import { Col, Row } from 'react-bootstrap';
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import TdtNumberAddon from 'components/websitecomponents/TdtNumberAddon';
import TdtTable from 'components/websitecomponents/TdtTable';
import TdtButton from 'components/websitecomponents/TdtButton';
import { WebApi } from 'shared/WebApi';
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtInputNumber from 'components/websitecomponents/TdtInputNumber';
 

type TableRowSelection<T> = TableProps<T>['rowSelection']; 
interface TdtBookDrwerprops {
  open?: boolean; 
  onClose?: any; 
  title?: string;
  destroyOnClose?: boolean;
  pid?: string;
} 
interface DataType {
  key: any;
  tour_option: string;
  price: string;
  qty_adults: string;
  qty_childern: string;
  addon_price: string;
} 
interface AdditionalOption {
  [x: string]: any;
  title: string;
  price: number;
  persons: number;
} 
interface PackageDataType {
  pid: number;
  cat_id: number;
  cat_title: string;
  title: string;
  description: string;
  price: number;
  discount_price: number;
  child_price: number;
  child_discount_price: number;
  duration_type: string;
  duration: string;
  rating: number;
  package_image: string;
  package_image_thumbnail: string;
  transfer_type: string;
  transfer_type_adultprice: number;
  transfer_type_childprice: number;
  transfer_type_persons:number;
  tags: string;
  image: string;
  opt_title: string;
  opt_price: number;
  opt_persons: number;
  long_description: string;
  SlideImages: string[];  
  AdditionalOptions: { [key: string]: AdditionalOption }; 
}

// const tabledata: DataType[] = [];
// for (let i = 0; i < 10; i++) {
//   tabledata.push({
//     key: i,
//     tour_option: `Tour Option ${i}`,
//     price: `AED 100 for ${i} Person(s)`,
//     qty_adults: `qty_adults. `,
//     qty_childern: `qty_childern. `,
//     addon_price: `addon_price. `,
//   });
// }

const TdtBookDrwer: React.FC<TdtBookDrwerprops> = (props) => {
  
  const [form] = Form.useForm();
  const formRef = React.useRef<FormInstance>(null);
  
  const [bookingDetails, setBookingDetails] = useState({
    adults: 2,
    children: 0,
    addonsAdults: 1,
    addonsChildren: 1,
  });

  const [packageData, setPackageData] = useState<PackageDataType | null>(null);
  const [tourOptionsTotal, setTourOptionsTotal] = useState(0);
  const [addonTotal, setAddonTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0); 
  const [sharingTransport, setSharingTransport] = useState('');
 
  
  function onChangeTranferTypr(date: any,dateString: any) {
    // console.log('this isdate',dateString); 
    setSharingTransport(sharingTransport)
     
  }

 
  function onChange(date: any,dateString: any) {
    // console.log('this isdate',dateString); 
    setAddtoCardValues(moment(dateString).format("YYYY-MM-DD")) 
  }
   

  useEffect(() => {
    getPackageById();
  }, [props.pid,props.open]); 
   
    const [setartDate, setAddtoCardValues] = useState({}); 
    const onAddToCarts = async () => {
    // localStorage.setItem('setStartDate', JSON.stringify(setartDate)) 
    // localStorage.setItem('addonTotal', JSON.stringify(addonTotal)) 
    // localStorage.setItem('grandTotal', JSON.stringify(grandTotal)) 
    // localStorage.setItem('sharingTransport', JSON.stringify(sharingTransport)) 
    // localStorage.setItem('propsPid', JSON.stringify(props.pid))   

    // navigate('/check-out') 

  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    
  };

interface DataType {
  key: any;
  title: any;
  price: any;
  persons: any;
  totalAddonPriceAdults?: number;  // To store the calculated price for adults
  totalAddonPriceChildren?: number;  // To store the calculated price for children
 
}

  
const [pkgData, setPkgData] = useState<any>([]);

const getPackageById = async () => {
  try {
    const response = await WebApi('get', `api/get_package_byId/${props.pid}`);
    const responseData: any = response;
    if (responseData.status === 200) {
      const modifiedData = responseData.data.data; 
      form.setFieldsValue (modifiedData) 
      setPackageData(modifiedData);
      setPkgData(
        Object.values(modifiedData?.AdditionalOptions || {}).map(
          (record:any) => ({
            key: record.ad_opt_id.toString(),
            title: record.title,
            price: record.price,
            persons: record.persons,
            totaladdonprice: '',
          })
        )
      ); 
    }
    } catch (error) {
      console.error('Error:', error);
    }
  }; 
// Handle adult quantity change
const handleAdultQuantity = (index: string, price: number, quantity: number) => {
  // Update the total addon price for the adults
  const updatedPkgData = pkgData.map((item: any) =>
    item.key === index
      ? { 
          ...item,
          totalAddonPriceAdults: quantity * price, // Recalculate addon price for adults
        }
      : item
  );

  // Update the state with the new data
  setPkgData(updatedPkgData);
};

// Handle child quantity change
const handleChildQuantity = (index: string, price: number, quantity: number) => {
  // Update the total addon price for children
  const updatedPkgData = pkgData.map((item: any) =>
    item.key === index
      ? { 
          ...item,
          totalAddonPriceChildren: quantity * price, // Recalculate addon price for children
        }
      : item
  );

  // Update the state with the new data
  setPkgData(updatedPkgData);
};

// Calculate the total addon price for all items
const calculateTotalAddonPrice = () => {
  return pkgData.reduce((total: number, item: any) => {
    // Sum both the adult and child addon prices for each item
    return total + (item.totalAddonPriceAdults || 0) + (item.totalAddonPriceChildren || 0);
  }, 0);
};

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (_, record) => (
      <>AED {record.price} For {record.persons} Person(s)</>
    ),
  },
  {
    title: 'Qty / Adults',
    dataIndex: 'qadults',
    key: 'qadults',
    render: (_, record) => (
      <TdtInput
        type="number"
        value={record.persons}
        onchange={(e: any) =>
          handleAdultQuantity(record.key, record.price, parseInt(e.target.value || '0'))
        }
        requiredMessage="Transfer type adult required"
        name={`qty_persons_adult${record.key}`}
        min={0}
      />
    ),
  },
  {
    title: 'Child(ern)',
    dataIndex: 'childern',
    key: 'childern',
    render: (_, record) => (
      <TdtInput
        type="number"
        value={record.persons}
        onchange={(e: any) =>
          handleChildQuantity(record.key, record.price, parseInt(e.target.value || '0'))
        }
        requiredMessage="Qty child required"
        name={`qty_persons_child${record.key}`}
        min={0}
      />
    ),
  },
  {
    title: 'Addon Price',
    key: 'addonprice',
    render: (_, record) => (
      <>AED {(record.totalAddonPriceAdults ?? 0) + (record.totalAddonPriceChildren ?? 0)}</>
    ),  // Use fallback value 0 if undefined
  }
];

// Calculate and display the total addon price
const totalAddonPrice = calculateTotalAddonPrice(); 




// Calculate and display the total addon price
const [transferPerson, setTransferPerson] = useState<number>(0);  // Track adult count
const [transferChildren, setTransferChildren] = useState<number>(0);  // Track children count 
const pricePerPerson = packageData?.price ?? 0;
const pricePerChild = packageData?.child_price ?? 0; 
// Handler for adult quantity change
const handleTransferPersonChange = (newValue: number | '' | null) => { 
  const validValue = newValue === '' || newValue === null ? 0 : newValue;
  setTransferPerson(validValue); 
};  
// Handler for children quantity change
const handleTransferChildrenChange = (newValue: number | '' | null) => { 
  const validValue = newValue === '' || newValue === null ? 0 : newValue;
  setTransferChildren(validValue);
}; 
// Calculate total price
const tourtotalPrice = (transferPerson * pricePerPerson) + (transferChildren * pricePerChild);
 
  
const [formData, setFormData] = useState({
  tourtotalPrice:tourtotalPrice,
  totalAddonPrice: totalAddonPrice,
  addonData: pkgData,
}); 
const navigate = useNavigate();

const onAddToCart = (e: any) => {
  e.preventDefault();   

  navigate('/check-out', {
    state: formData,  
  });
  debugger
};



  return (
    <TdtDrawer title={props.title} open={props.open} onClose={props.onClose} width={'1250px'} destroyOnClose={props.destroyOnClose}>
      <Form
        name="add_toCart"
        form={form}
        onFinish={onAddToCart}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col sm={12} md={3}> 
            <TdtSelect 
              name='transfer_type'
              label='Select Transfer Type' 
              allowClear={false} 
              optionList={[ { value: packageData?.transfer_type, label: packageData?.transfer_type } ]} // Define options for transfer type
              defaultActiveFirstOption={true} 
              value={packageData?.transfer_type}
              onChange={(value:any) => setSharingTransport(value)}
            />
          </Col>
          <Col sm={12} md={3}>
            <TdtDate label="Select Tour Date" name="start_date" onChange={onChange} className='w-100' required={true} requiredMessage="Start Date is Required" />
          </Col>
          <Col sm={12} md={3} className='d-flex flex-column'>
            <Form.Item label={`No of Adults ${packageData?.price}`} name={'transfer_type_adult_persons'}>
                <InputNumber  
                  min={packageData?.transfer_type_persons}
                  onChange={handleTransferPersonChange} 
                  className='w-100'
                />
            </Form.Item> 
          </Col>
          <Col sm={12} md={3} className='d-flex flex-column'>
          <Form.Item label={`No of Children  ${packageData?.child_price}`} name={'transfer_type_persons_children'} className='w-100'>
              <InputNumber  
               className='w-100'
                min={packageData?.transfer_type_persons}
                max={transferPerson}
                onChange={handleTransferChildrenChange} 
              />
          </Form.Item>
              
          </Col>
          <Col sm={12} className='position-relative'>
            <h5>Customize Additional Inclusions</h5>
            <Table<DataType> columns={columns} dataSource={pkgData} />
            {/* <TdtTable pagination={false} tblheadcolumns={columns} tabledata={packageData?.AdditionalOptions} /> */}
            <div className='d-flex justify-content-between tblfooterdetail'>
              <div className='d-flex gap-4'>
                <div className='tourtotal'>Tour Total: <span><b>{tourtotalPrice} </b></span></div>
                <div className='addontotal'>Addon Total: <span><b>{totalAddonPrice}</b></span></div>
              </div>
              <div className='d-flex gap-4'>
                <div className='grandtotal'>Grand Total: <span><b>{(tourtotalPrice) + (totalAddonPrice)}</b></span></div>
              </div>
              <TdtButton onClick={onAddToCart}  label={'Add To Cart'}/>
            </div>
          </Col>
        </Row> 
      </Form> 
    </TdtDrawer>
  );
};

export default TdtBookDrwer;
