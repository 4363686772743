import React from 'react'; 
import { NavLink } from 'react-router-dom';
import { Image } from 'antd';

interface Tdtimageprops{
    src?:any
    label?:any
    tolink?:string
    classNamelinkdiv?:string
    classNameimg?:string
    classNamelbl?:string
    preview?:boolean
    width?:any
    alt?:any 
    fallback?:any

}

const TdtImage: React.FC<Tdtimageprops> = (props) => {
  return (
    <React.Fragment> 
        {
         props.tolink? (<NavLink to={props.tolink} className={`${props.classNamelinkdiv? props.classNamelinkdiv : ''}`}>
            <span className={`imgset ${props.classNameimg? props.classNameimg  : ''}`}>
                <Image  fallback={props.fallback} src={props.src} preview={props.preview} width={props.width}  className={` ${props.classNameimg? props.classNameimg  : ''}`} alt={props.alt}/>
            </span>{props.label  && <span className={`lblset ${props.classNamelbl? props.classNamelbl : ''}`}>{props.label}</span>}
         </NavLink>) 
         :
         (
         <div  className={`${props.classNamelinkdiv? props.classNamelinkdiv : ''}`}>
            <span className={`imgset ${props.classNameimg? props.classNameimg : ''}`}> 
                <Image fallback={props.fallback} src={props.src} preview={props.preview} width={props.width}  className={` ${props.classNameimg? props.classNameimg  : ''}`} alt={props.alt}/>
            </span>{props.label  && <span className={`lblset ${props.classNamelbl? props.classNamelbl : ''}`}>{props.label}</span>}
         </div>)
        }
    </React.Fragment>
  );
}

export default TdtImage;