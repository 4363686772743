import TdtButton from 'components/websitecomponents/TdtButton';
import React, {  useEffect, useState } from 'react';   
import { Col, Container, Row } from 'react-bootstrap'; 
import AboutDeluxeDrwr from './AboutDeluxeDrwr';
import { WebApi } from 'shared/WebApi';
import { Form, FormInstance, message } from 'antd';
import TdtImage from 'components/websitecomponents/TdtImage';
import { Image } from 'antd';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaRegTrashCan, FaTrash } from 'react-icons/fa6';
import { HiTrash } from 'react-icons/hi';

const projectpath = process.env.REACT_APP_API_URL;

interface Dashboardprops {}
  
export const OurGallery: React.FunctionComponent<Dashboardprops> = (props) => { 
 
const [form] = Form.useForm()
const formRef = React.useRef<FormInstance>(null); 
const [aboutDeluxe, setAddAboutDeluxe] = useState(false); 
const addAboutDeluxeFun = () => {
    setAddAboutDeluxe(true);
}  
const onClose = () => { 
    setAddAboutDeluxe(false);
};
 
const [disableButton, setDisableButton] = useState(false)
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
    setDisableButton(true) 
  };  
const onFinish = async (values: any) => {  
    values.gallery_image = imageThumbnailUpload  
    try {
        setDisableButton(true)
        const response = await WebApi('post', 'api/insert_galler_img' , values, 'multipart');  
        const responseData: any = response; 
        if (responseData.status === 200) { 
                message.success('Gallery Image Added')  
                setDisableButton(false) 
                getGalleryImages()  
            }
        else {
        console.error('Error: Request error');   
        setDisableButton(true) 
        }
    } catch (error:any) {
        console.error('Error:', error);  
        setDisableButton(true) 
    }
  }; 

  
    useEffect(() => {
        getGalleryImages();
    }, []); 
  
  
    const [galleryImages, setGalleryImages] = useState<any[]>([]);
    const getGalleryImages = async () => {   
        try {
        const response = await WebApi('get', 'api/get_galler_img');
        const responseData: any = response;  
        if (responseData.status === 200) { 
            const modifiedData = responseData.data.data; 
            // const checkArrayObj = Array.isArray(modifiedData.data); 
            // console.log(checkArrayObj); 
            setGalleryImages(modifiedData) 
        }
        } catch (error:any) {
         console.error('Error:', error);  
        } 
    };



    const [imageThumbnailUpload, packageImageThumbnailUpload] = useState<File | null>(null);
        const packageImageThumbnailUploadFun = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        packageImageThumbnailUpload(file);   
    };
   
     const deletePackage = async (imgId: any) => {
       try {
         const response = await WebApi('post', `api/delete_galler_img/${imgId}`); 
         const responseData: any = response; 
         if (responseData.status === 200) { 
           message.success('Image deleted successfully');
           getGalleryImages();  
         }
       } catch (error:any) {
         console.error('Error:', error);  
       } 
     }; 
     


    return (  
        <React.Fragment>   
           <Container fluid className='dashboardpage'> 
                <Container  className='px-0 py-4'>
                    <Form
                    name="add_product"
                    form={form}
                    ref={formRef} 
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    >
                        <Row>  
                            <Col sm={12}  md={3} className='d-flex flex-column border'> 
                                {/* <label className='customlabel'>Upload Gallery Image</label> */}
                                <input type="file" onChange={packageImageThumbnailUploadFun} />
                            </Col> 
                            <Col sm={12}  md={3}>
                                <TdtButton disable={disableButton} label={'Save'} htmlType="submit" classNamebtn='me-2 px-5 mt-3'/> 
                            </Col>   
                        </Row>
                        <Row className='pt-3'>    
                            {galleryImages.map((item:any, i:any) => (  
                                <Col sm={2}>  
                                    <TdtImage src={projectpath+"public/panelassets/images/gallery/"+item.gallery_image} preview={true}/>
                                    <TdtIcon icon={<FaRegTrashCan />}  onClick={()=>deletePackage(item.gallery_images_id)}/>
                                </Col>  
                            ))}  
                        </Row>
                    </Form>
                </Container>
            </Container> 
        </React.Fragment>
    )
}

export default OurGallery;




