// api.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL; // Replace with your API base URL
const yourToken = localStorage.getItem('token');

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${yourToken}`, // Bearer token will be set here
  },
});

const logout = () => {
  localStorage.setItem('loginStatus', "logout");
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('username');
  localStorage.removeItem('email');
  localStorage.removeItem('auth');
  setTimeout(() => {
      window.location.href = '/deluxe-login'; // Redirect to login page after logout
  }, 500);
  localStorage.clear();
  debugger
}
export const WebApi = async (method: 'post' | 'get' | 'put' | 'delete', url: string, values: any = null,  header = "") => {
// export const WebApi = async (method:any, url, data = null, header = "") => {
  try {
    // Set Authorization header dynamically if required (in case token is changed)
    const token = localStorage.getItem('token');
    api.defaults.headers['Authorization'] = `Bearer ${token}`;

    // If multipart form data is required
    if (header === "multipart") {
      api.defaults.headers['Content-Type'] = "multipart/form-data"; // Change content type
    }

    // If it's a URL-encoded form request
    if (header === "delete") {
      api.defaults.headers['Content-Type'] = "application/x-www-form-urlencoded"; // Set for URL-encoded requests
    }

    // Make the API request based on the method
    const response = await api[method](url, values);
    return response;

  } catch (error:any) {
    if (error.response && error.response.status === 401) {
      logout();
      debugger
    }
    throw error; // Propagate error in case of other issues
  }
};
