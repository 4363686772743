import React, { Children, useState } from 'react';
import { Button, Drawer } from 'antd';


interface TdtDrawerprops { 
    open?:boolean
    onClose?:any
    children?:any
    title?:string
    width?:string
    destroyOnClose?:boolean
    placement?: any
    className?:string
}


const TdtDrawer: React.FC<TdtDrawerprops> = (props) => {


  return ( 
      <Drawer title={props.title} placement={props.placement} destroyOnClose={props.destroyOnClose} onClose={props.onClose} open={props.open} width={props.width} className={props.className}>
        {props.children}
      </Drawer> 
  );
};

export default TdtDrawer;