/*
    IMPORTANT: 
    YOU ARE NOT ALLOWED TO REMOVE THIS COMMENT AND NO MODIFICATION TO THE CODE SHOULD BE MADE WITHOUT THE CONSENT OF THE AUTHOR


    DISCLAIMER:
    This code is provided "as is" after proper verifications and reviews to the Development Team. 
    The author to this file shall not be held liable for any damages, including any lost profits or other 
    incidental or consequential damages arising out of or in connection with the use or inability to use this code.
    
    © Copyright [14th Jan 2023] [Haseeb Ahmed Khan] [Valentia Technologies Limited]
    

     Contributors: 
    1) Malik Ehtasham
*/


import React from 'react';
import { Form, Select } from 'antd';
import { propTypes } from 'react-bootstrap/esm/Image';


type SelectMode = 'multiple' | 'tags';

// interface Option {
//     value: string;
//     label: string;
// }
interface TdtSelectProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    required?: boolean;
    requiredMessage?: string;
    prefix?: any; //add text or icon at start of input
    suffix?: any; //add text or icon at end of input
    offset?: number;
    span?: number;
    allowClear?: boolean;
    type?: string;
    ruleType?: any;
    optionList: Array<{}>,
    value?: any,
    onChange?: any,
    placeholder?: string,
    mode?: SelectMode,
    disabled?: boolean,
    filterOption?: any,
    filterSort?: any,
    showSearch?: boolean,
    loading?: boolean,
    defaultActiveFirstOption?: boolean,
    virtual?: boolean
    autoClearSearchValue?: boolean
    maxCount?:any
}



const TdtSelect: React.FunctionComponent<TdtSelectProps> = (props) => {

    const onSearch = (value: string) => {
        // alert('Value' + props?.value || [])
    };

    return (
        <> 
            <Form.Item label={props.label}  name={props.name} required={props.required} rules={[{ required: true, message: 'Please input!' }]} >
                <Select
                    style={{ width: '100%' }} 
                    options={props.optionList}
                    virtual={props.virtual}
                    value={props.value}
                    onChange={props.onChange}
                    autoClearSearchValue={props.autoClearSearchValue}
                    //Optional Props
                    disabled={props?.disabled}
                    mode={props.mode}
                    placeholder={props.placeholder ? props.placeholder : "-Select-"}
                    showSearch={props.showSearch}
                    showArrow
                    allowClear={false}
                    defaultActiveFirstOption={props.defaultActiveFirstOption}
                    optionFilterProp="children"
                    onSearch={onSearch}
                    maxTagCount='responsive'
                    filterOption={props.filterOption}
                    filterSort={props.filterSort}
                    loading={props.loading} 
                    maxCount={props.maxCount}
                />
            </Form.Item>
        </>
    )
}
export default TdtSelect;

 