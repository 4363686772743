import React from 'react';
import logo from './logo.svg'; 
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { history } from "./shared";
import './App.css';
import Weblayout from './website'
import Home from 'website/Home';
import Login from 'Auth/Login';
import AboutUs from 'website/AboutUs';
import ForgetPassword from 'Auth/ForgetPassword';
import ResetPassword from 'Auth/ResetPassword';
import Register from 'Auth/Register';
import PackgesByCategories from 'website/PackgesByCategories';
import TourDetail from 'website/TourDetail';
import Gallery from 'website/Gallery';
import Cmslayout from 'components/cmslayout/Cmslayout';
import ContactUs from 'website/ContactUs';  
import TdtCheckOut from 'website/TdtCheckOut';
import PasswordForget from 'Auth/PasswordForget';
import Dashboard from 'cmspages/Dashboard'; 
import AddProduct from 'cmspages/ViewProduct';
import ViewProduct from 'cmspages/ViewProduct';
import AddAboutUs from 'cmspages/AddAboutUs';
import PrivacyPolicy from 'website/PrivacyPolicy';
import ManageSetting from 'cmspages/ManageSetting';
import OurGallery from 'cmspages/OurGallery';
import PrivateRoutes from 'shared/helpers/PrivateRoutes';

function App() { 
  // const users = [1,2,3,4,5,6,7,8,9,10];
  // const userspage = ['abc','def','ghi']; 
  return ( 
    <BrowserRouter>
      <Routes>  
          <Route path="/" element={<Weblayout className='appweblaout' children={<Home />}  />} />
          {/* <Route path="/desert-safari-dubai-tours" element={<Weblayout className='appweblaout' children={<DesertSafariDubaiTours />}  />} /> */}
          <Route path="/sight-seeing-dubai-tours" element={<Weblayout className='appweblaout' children={<PackgesByCategories />}  />} />
          <Route path="/private-tours-dubai-tours" element={<Weblayout className='appweblaout' children={<PackgesByCategories />}  />} />
          <Route path="/executive-dubai-tours" element={<Weblayout className='appweblaout' children={<PackgesByCategories />}  />} />
          <Route path="/dhow-cruise-dubai-tours" element={<Weblayout className='appweblaout' children={<PackgesByCategories />}  />} />
          <Route path="/combo-deals-dubai-tours" element={<Weblayout className='appweblaout' children={<PackgesByCategories />}  />} />
          <Route path="/tour-detail" element={<Weblayout className='appweblaout' children={<TourDetail />}  />} />
          <Route path="/about-us" element={<Weblayout className='appweblaout' children={<AboutUs />}  />} />
          <Route path="/privacy-policy" element={<Weblayout className='appweblaout' children={<PrivacyPolicy />}  />} />
          <Route path="/gallery" element={<Weblayout className='appweblaout' children={<Gallery />}  />} />
          <Route path="/contact-us" element={<Weblayout className='appweblaout' children={<ContactUs />}  />} />
          <Route path="/check-out" element={<Weblayout className='appweblaout' children={<TdtCheckOut />}  />} />
          {/* <Route path="/admin-panel" element={<Cmslayout className='appweblaout' children={<Dashboard />}  />} /> */}
          <Route path="/add-about" element={<Cmslayout className='appweblaout' children={<AddAboutUs />}  />} />
          {/* <Route path="/view-packages" element={<Cmslayout className='appweblaout' children={<ViewProduct />}  />} />  */}
          <Route path="/manage-setting" element={<Cmslayout className='appweblaout' children={<ManageSetting />}  />} /> 
          <Route path="/our-gallery" element={<Cmslayout className='appweblaout' children={<OurGallery />}  />} />
          <Route path="/deluxe-login" element={<Login />} /> 
          <Route path="/forget-password" element={<PasswordForget />} /> 
          <Route path="/reset-password" element={<ResetPassword />} /> 
          <Route path="/register" element={<Register />} /> 
 
 
          <Route
            path="/admin-panel"
            element={
              <PrivateRoutes redirectPath="/deluxe-login" isAllowed={localStorage.getItem("token")? true : false} >
                <Cmslayout className='appweblaout' children={<Dashboard />}  />
              </PrivateRoutes>
            }
          > 
          </Route>

          <Route
            path="/view-packages"
            element={
              <PrivateRoutes redirectPath="/deluxe-login" isAllowed={localStorage.getItem("token") != ""} >
                <Cmslayout className='appweblaout' children={<ViewProduct />}  />
              </PrivateRoutes>
            }
          > 
          </Route>




          {/* <Route path="/product/:productName" element={<TourDetail />} /> */}
          <Route path="/tours/:id" element={<Weblayout className='appweblaout' children={<TourDetail />}  />} />
          <Route path="/tours/category/:id" element={<Weblayout className='appweblaout' children={<PackgesByCategories />}  />} />
          <Route path="/tours" element={<Weblayout className='appweblaout' children={<PackgesByCategories />}  />} /> 

          {/* <Route path={`${userspage[0]}/1`} element={<TourDetail />}/>
          <Route path={`${userspage[1]}/2`} element={<AboutUs />} />
          <Route path={`${userspage[2]}/3`} element={<AboutUsPage />}/> */}

          
      </Routes>
  </BrowserRouter>
  );
}

export default App;
